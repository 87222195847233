import { BillingPeriodUnit } from "src/components/Account/types"
import { TCurrentSubscription } from "src/data/billing/types/billingTypes"
import { PLAN, TUser } from "src/data/user/user"
import { ITranslateFunction } from "src/i18n/i18nTypes"
import { langKeys } from "src/i18n/langKeys"

export function translatePlanName(
  t: ITranslateFunction,
  plan: TCurrentSubscription["plan"] | TUser["current_plan"],
  isLegacy?: boolean
) {
  const planName = {
    [PLAN.starter]: t(langKeys.subscription_no_plan_title),
    [PLAN.standard]: t(langKeys.standard),
    [PLAN.pro]: t(langKeys.pro),
    [PLAN.pro_plus]: t(langKeys.pro_plus),
    "": null,
  }[plan ?? ""]
  if (!planName) {
    throw new Error("Unknown plan")
  }

  if (plan === PLAN.starter) {
    return planName
  }

  return isLegacy ? `${planName} (Legacy)` : planName
}

export function getPlanDescriptionKey(
  currentSubscription: TCurrentSubscription | undefined
) {
  if (!currentSubscription) return null

  const {
    plan,
    legacy_plan: isLegacyPlan,
    billing_period: billingPeriod,
    custom_plan: customPlan,
  } = currentSubscription

  if (!isLegacyPlan) {
    return null // no description needed for non-legacy plans
  }
  if (customPlan) {
    return null // for now, show no description for custom plans
  }

  function getKey() {
    if (billingPeriod === "month" && plan === "standard") {
      return langKeys.subscription_standard_legacy_description_monthly
    }
    if (billingPeriod === "month" && plan === "pro") {
      return langKeys.subscription_pro_legacy_description_monthly
    }
    if (billingPeriod === "year" && plan === "standard") {
      return langKeys.subscription_standard_legacy_description
    }
    if (billingPeriod === "year" && plan === "pro") {
      return langKeys.subscription_pro_legacy_description
    }

    throw TypeError(
      `Unrecognized plan, couldn't get plan text ${JSON.stringify({
        plan,
        isLegacyPlan,
        billingPeriod,
      })}`
    )
  }

  return getKey()
}

export function translateBillingPeriod(
  t: ITranslateFunction,
  billingPeriodUnit?: TCurrentSubscription["billing_period"]
) {
  if (billingPeriodUnit === BillingPeriodUnit.MONTH) {
    return t(langKeys.month)
  } else if (billingPeriodUnit === BillingPeriodUnit.YEAR) {
    return t(langKeys.year)
  } else {
    return "N/A"
  }
}
